<template>
  <div id="assign-exam">
    <vx-card>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12>

          </v-flex>
        </v-layout>
      </v-container>
    </vx-card>
  </div>
</template>

<script>
  import request from "./common/HttpRequests";

  export default {
    name: "AssignExam",
    data() {
      return {}
    },
    methods: {
      getTopics() {
        this.$vs.loading();

        request
          .HttpGet("/api/Lexicon/Topic")
          .then(response => {
            this.treeTopicData = response.data.Topics;
          })
          .catch(() => {
            this.$vs.notify({
              title: "Error",
              text: "Cannot connect to server",
              color: "danger"
            });
          })
          .finally(() => {
            this.$vs.loading.close();
            this.selectedQuestions = [];
          });
      },
      getUsers() {

      },
    },
    mounted() {
      this.getTopics();
    },
    components: {}
  }
</script>

<style scoped>

</style>
