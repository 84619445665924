import axios from "@/axios.js";

export default {
    HttpGet(URL) {
        return axios.get(URL);
    },
    HttpPost(URL, body) {
        return axios.post(URL, body);
    },
    HttpPostFile(URL, body, headers) {
        return axios.post(URL, body, headers);
    },
    HttpDelete(URL) {
        return axios.delete(URL);
    },
    HttpPut(URL, body) {
        return axios.put(URL, body);
    }
}